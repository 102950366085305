import React from "react"

import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"
import TextLink from "@kiwicom/orbit-components/lib/TextLink"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Parental Leave">
    <Hero title="Parental Leave" />
    <Container size="medium">
      <EmberCard>
        <EmberCardSection>
          <Stack>
            <Text>
              The government has a{" "}
              <TextLink
                external
                href="https://www.gov.uk/browse/childcare-parenting"
              >
                good guide
              </TextLink>{" "}
              to your exact rights, but you can also talk to us and we'll work
              through it together. We'll try to be flexible rather than just
              doing the bare minimum.
            </Text>
          </Stack>
        </EmberCardSection>
      </EmberCard>
    </Container>
  </DriveLayout>
)

export default Page
